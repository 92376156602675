import React from 'react';
import '../App.css';

const LoadingScreen = () => {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
      </div>
    );
};

export default LoadingScreen;